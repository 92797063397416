
<template class="">
    <nav class="pf ov ba headerLight z1000 pv10">
      <div class="flexi">
        <tbl_nm>
          <cl class="vm l" style="width:125px">
            <router-link :to="{ name: 'Home' }"><img src="@/assets/imgs/logoDark.png" alt="logo" title="trendshift" class="w100"></router-link>
          </cl>
          <cl class="colSep50 m_h"></cl>
          <cl class="vm r">
            <i class="fas fa-globe ml10 mr10 pointer cOrange" @click="changeLang()"></i> 
          </cl>
        </tbl_nm>
      </div>
    </nav>
    <div class="pt60"><router-view></router-view></div>
  <div v-if="$store.state.loader==1 || $store.state.pageLoader==1" class="pf ov bgw" style="z-index: 9999999;" :style="{ opacity: $store.state.pageLoader === 1 ? 0.7 : 1 }">
    <div class="cc">
      <div class="loader">$</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "WebLayout",
    created(){
      if(this.$cookies.isKey("_TrendLang")){
        if(this.$cookies.get("_TrendLang")=='en'){
          this.$i18n.locale='ar';
        }
        else{
          this.$i18n.locale='en';
        }
        this.changeLang()
      }
    },
    methods:{
      changeLang(){
        if(this.$i18n.locale=="en"){
          $('html').attr('lang', 'ar');  
          this.$i18n.locale='ar';
          $('body').addClass('rtl');
          const expirationTime =  10000 * 60 * 60;
          this.$cookies.set("_TrendLang", 'ar', expirationTime);
        }
        else{
          $('html').attr('lang', 'en');  
          this.$i18n.locale='en';
          $('body').removeClass('rtl');
          const expirationTime =  10000 * 60 * 60;
          this.$cookies.set("_TrendLang", 'en', expirationTime);
        }
      },
    }
  }
</script>
