import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import VueAxios from "vue-axios";
import axios from "axios";
import VueToast from 'vue-toast-notification';
import VueCookies from "vue-cookies";
import Select2 from "vue3-select2-component";

import 'vue-toast-notification/dist/theme-sugar.css';
import "./assets/css/bea.css";
import "./assets/css/customStyle.css";
const app = createApp(App).use(store).use(router);
app.component("Select2", Select2);

app.use(i18n);
app.use(VueAxios, axios);
app.use(VueCookies);
app.use(VueToast);

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {}
//     return Promise.reject(error);
//   }
// );

app.mount("#app");
