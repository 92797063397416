import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {},
    'ar': {
      'Home':"الرئسية",
      'Contact us':"تواصل معنا",
      'Terms of Use':"شروط الإستخدام",
      'Sign in Page':"صفحة تسجيل الدخول",
      'With Trends shift,':"مع  ترندز شفت",
      'We believe in a more beautiful future.':"نؤمن بمستقبل أكثر جمالاً.",
      'Together we determine the best direction.':"معًا نحدد الاتجاه الأفضل.",
      'With the right tools we provide to you.':"باستخدام الأدوات المناسبة التي نوفرها لك.",
      'Trends':"ترندز",
      'Shift can':"شفت بإمكانها",
      'Help':"أن تساعدك",
      'Talk to us':"تحدث معنا",
      'Full Name':"الاسم الكامل",
      'Email Address':"البريد الإلكتروني",
      'Your Message':"رسالتك",
      'Full Name is required':"الرجاء إدخال الاسم الكامل",
      'Email Address is required':"الرجاء إدخال البريد الإلكتروني",
      'Your Message is required':"الرجاء إدخال رسالتك",
      'Please enter valid email':"الرجاء التأكد من البريد الإلكتروني",
      'Submit':"إرسال",
      'Failed to send message please try again later':"فشل إرسال الرسالة الرجاء إعادة المحاولة لاحقاً",
      'Message sent successfully':"تم إرسال الرسالة بنجاح",
      'Terms and Conditions.':"الشروط والأحكام.",
      'Download PDF File':"تحميل الملف",
      'Create your account':"",
      'Fill the form below to create your account':"",
      'Type your full name':"أدخل اسمك الكامل",
      'Type your user name':"أدخل اسم المستخدم",
      'User Name':"اسم المستخدم", 
      'Create your account':"قم بإنشاء حسابك",
      'Fill the form below to create your account':"قم بملء النموذج أدناه لإنشاء حسابك",
      'Phone Number':"رقم الهاتف",
      'Type your phone number':"أدخل رقم الهاتف",
      'Type your password':"أدخل كلمة المرور",
      'Password':"كلمة المرور",
      'Confirm Password':"تأكيد كلمة المرور",
      'Refferral Link':"رابط الإحالة",
      'Next Step':"الخطوة التالية",
      'Type your email address':"أدخل بريدك الإلكتروني",
      'Password do not match':"كلمة المرور لا تتطابق",
      'Password must be at least 8 characters':"يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
      'Password is required':"الرجاء إدخال كلمة المرور",
      'Confirm Password is required':"الرجاء تأكيد كلمة المرور",
      'Phone Number is required':"الرجاء إدخال رقم الهاتف",
      'We need some information to verify your account':"نحن بحاجة إلى بعض المعلومات للتحقق من حسابك",
      'Back':"رجوع",
      'Create Account':"إنشاء حساب",
      'Date of Birth':"تاريخ الميلاد",
      'Date of Birth is required':"الرجاء إدخال تاريح الميلاد",
      'Address':"العنوان",
      'Address is required':"الرجاء إدخال العنوان",
      'Type your address':"أدخل عنوانك",
      'Profession':"المهنة",
      'Profession is required':"الرجاء إدخال المهنة",
      'Type your profession':"أدخل مهنتك",
      'Identification Type':"نوع بطاقة التعريف",
      'Identification Type is required':"الرجاء إختيار نوع بطاقة التعريف",
      'Passport':"جواز سفر",
      'Driving License':"شهادة سواقة",
      'Identification Number':"رقم بطاقة التعريف",
      'Identification Number is required':"الرجاء إدخال رقم بطاقة التعريف",
      'Type your identification number':"أدخل رقم بطاقة التعريف",
      'Identification Photo':"صورة بطاقة التعريف",
      'Identification Photo is required':" الرجاء تحميل بطاقة التعريف",
      'Upload a Selfie':"صورة سلفي",
      'Selfie is required':" الرجاء تحميل صورة سلفي",
      'Upload the document here':"قم بتحميل الملف هنا",
      'Max size is 5Mb':"الحجم الأقصى 5MB",
      'supported files':"الملفات المدعومة",
      'Under Review':"قيد المراجعة",
      'The information you uploaded is under review':"المعلومات التي قمت بتحميلها قيد المراجعة",
      'We will notify you when your account is ready and approved.':"سنقوم بإعلامك عندما يصبح حسابك جاهزًا ويتم الموافقة عليه.",
      'Go back home':"العودة إلى الرئيسية",
      'Email Address / Username':"البريد الإلكتروني / اسم المستخدم",
      'This field is required':"هذا الحقل إجباري",
      'Type your email/username':"اكتب بريدك الإلكتروني/اسم المستخدم",
      'Forgot password?':"نسيت كلمة المرور؟",
      'Sign in':"تسجل الدخول",
      'Failed to logged in please try again later':"فشل تسجيل الدخول الرجاء إعادة المحاولة لاحقاً",
      'Logged in success':"تم تسجيل الدخول بنجاح",
      'Failed to register please try again later':"فشل إنشاء الحساب الرجاء إعادة المحاولة",
      'First Name':"الاسم الأول",
      'First Name is required':"الرجاء إدخال الاسم الأول",
      'User Name is required':"الرجاء إدخال الاسم المستخدم",
      'Last Name is required':"الرجاء إدخال الاسم الأخير",
      'Last Name':"الاسم الأخير",
      'Type your first name':"أدخل اسمك الأول",
      'Type your last name':"أدخل اسمك الأخير",
      'The username has already been taken.':" اسسم المستخدم هذا مسجل مسبقاً",
      'The email has already been taken.':"البريد الإلكتروني مسجل مسبقاً.",
      'These credentials do not match our records':"إن البريد الإلكتروني او  كلمة السر لا يتطابق مع البينات لدينا",
      'Sign in to your account':"تسجيل الدخول إلى حسابك",
      '':"",
      '':"",
      '':"",
      '':"",
      '':"",
      '':"",
      'Subscribe to our':"اشترك في",
      'premium services':"خدماتنا المميزة",
      'Commission':"العمولة",
      'how much do you make?':"كم تربح؟",
      'Contact us via Whatsapp':"تواصل معنا عبر الواتس اب",
      'Trends':"ترندز",
      'Signal':"سيجنال",
      'Join the group for the latest Trends signals':"انضم للمجموعة للحصول على أحدث الاتجاهات",
      'Join us via Whatsapp':"انضم إلينا عبر الواتس اب",
      'Referral Link':"رابط الإحالة",
      'Invalid invitation pin':"رمز الإحالة غير صحيح",
      'Logout':"تسجيل خروج",
      'Failed to get data please try again later':"فشل في الحصول على البيانات، يرجى المحاولة مرة أخرى لاحقًا",
      'Nationality is required':"الرجاء إختيار الجنسية",
      'Nationality':"الجنسية",
      'Dashboard':"لوحة التحكم",
      'Middle Name':"الاسم الاوسط",
      'Type your middle name':"أدخل الاسم الاوسط",
      'Middle Name is required':"الرجاء إدخال الاسم الاوسط",
      'ID':"الهوية",
      'Please read these terms and conditions carefully before using our platform.':"يرجى قراءة هذه الشروط والأحكام بعناية قبل استخدام منصتنا.",
      'Select Nationality':"إختر الجنسية",
      'Password reset link sent to your email':"تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني",
      'Failed reset password please try again later':"فشلت عملية إعادة تعيين كلمة المرور، يرجى المحاولة مرة أخرى لاحقًا",
      'Social Media':"وسائل التواصل الاجتماعي",
      'I agree that my data will be securely stored and will not be used for any purpose other than account creation and management, and I agree to all the terms and conditions of the company.':"أوافق على أن بياناتي سيتم تخزينها بشكل آمن ولن يتم استخدامها لأي غرض آخر غير إنشاء الحساب وإدارته، و أوافق على كافة الشروط الأحكام الخاصة بالشركة.",
      'Change Password':"تغيير كلمة المرور",
      'Password changed succsefully':"تم تعديل كلمة المرور بنجاح",
      'Failed to change password please try again later':"فشل تغيير كلمة المرور الرجاء إعادة المحاولة لاحقاً",
      'Could not find this account':"لم يتم العثور على هذا البريد",
      'This link is invalid':"هذا الرابط غير صحيح",
      'This link is invalid, please check your email':"هذا الرابط غير صحيح، الرجاء التأكد من بريدك الإلكتروني",
      'Shift':" شفت",
      'Account verified and it is under review now':"تم توثيق الحساب وهو قيد المراجعة الآن",
      'Email Verfication sent':"تم إرسال بريد التوثيق",
      'Please check your email to verfiy your account':"الرجاء التأكد من بريدك الإلكتروني لتوثيق الحساب",
      'You are only allowed to enter characters from a to z and 0 to 9 and space':"يسمح لك بإدخال من a إلى z ومن 0 إلى 9 وفراغ بينهم",
      'You are only allowed to enter characters from a to z and 0 to 9 with no space':"يسمح لك بإدخال من a إلى z ومن 0 إلى 9 مع عدم وجود فراغات بينهم",
      'You are only allowed to enter characters from a to z and 0 to 9 and special characters with no space':"يسمح لك بإدخال من a إلى z ومن 0 إلى 9 وبعض الرموز مع عدم وجود بينهم",
      'You are only allowed to enter from 0 to 9 with no space':"يسمح لك إدخال من 0 إلى 9 مع عدم وجود فراغات بيتهم",
      'Contact us via whatsapp':"تواصل معنا الآن من خلال الواتس آب",
      'Support Line':"خط الدعم",
      'Funds':"الموارد المالية",
      'TrendsShif packge':"باقات ترندشفت",
      'Team':"الفريق",
      'Ranks':"الرتب",
      'Commession/Profit':"عمولة/عوائد",
      'TrendsShift Academy':"أكادمية ترندشفت",
      'News & Tools':"أخبار وأدوات",
      'Start today, do not wait for tomorrow':"ابدأ اليوم ولا تنتظر الغد",
      'Member':"عضو",
      'Subscribe Now':"إشتراك الآن",
      'Can not take photo':"لم تتمكن من إلتقاط الصورة",
      'Take Photo':"إلتقاط صورة",
      'Retake Photo':"إعادة إلتقاط صورة",
      'Open Selfie Camera':"فتح الكاميرا الأمامية",
      '':"",
      '':"",
      '':"",
      '':"",
      
      
      
      
      

      




      
    
    
    
    },
  },
})
export default i18n
