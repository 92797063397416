import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: "WebLayout",
    },
    component: () => import('../views/Pages/HomeView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      layout: "WebLayout",
    },
    component: () => import('../views/Pages/ContactView.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms',
    meta: {
      layout: "WebLayout",
    },
    component: () => import('../views/Pages/TermsView.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: {
      layout: "RegLayout",
    },
    component: () => import('../views/Pages/SignUpView.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    meta: {
      layout: "RegLayout",
    },
    component: () => import('../views/Pages/SignInView.vue')
  },
  {
    path: '/verify-account',
    name: 'VerifyAccount',
    meta: {
      layout: "RegLayout",
    },
    component: () => import('../views/Pages/VerifyAccountView.vue')
  },
  {
    path: '/create-password',
    name: 'ResetPassword',
    meta: {
      layout: "RegLayout",
    },
    component: () => import('../views/Pages/ResetPasswordView.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiredAuth:true,
    },
    
    component: () => import('../views/Dashboard/DashboardView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requiredAuth ) {
    if($cookies.isKey("_TrendsU") ){
      next();
    }
    else {
      next('/sign-in');
    }
  }
  
  else{ next();}

});

export default router
