
<template class="">
  <router-view></router-view>
  <div v-if="$store.state.loader==1 || $store.state.pageLoader==1" class="pf ov bgw" style="z-index: 9999999;">
    <div class="cc">
      <div class="loader">$</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "EmptyLayout",
    methods:{
    }
  }
</script>
